<template>
	<div class=''>
		<div class="banner relative">
			<img :src="bannerImg" class="w-max" style="object-fit: cover;">
			<!-- 侧边导航 -->

		</div>
		<div class="nav_box">
			<sideList :navList='navList' width='270px' @navBtn='navBtn' v-if="bannerImg" url='/coreJiShu' />
		</div>
		<router-view />
	</div>
</template>

<script>
	// import { mapMutations } from 'vuex'
	import sideList from "@/components/sideList"
	import bannerImgJs from '@/mixin/bannerImg'
	import { getSkillCategory, getSkills } from '@/api/index'
	export default {
		name: 'coreJiShu',
		data() {
			return {
				bannerImg: '',
				navList: [],
				list: [],
				bannerType: 1
			};
		},
		mixins: [bannerImgJs],
		components: { sideList },

		created() {
			this.$store.commit('editRouterName', this.$route.name)
			//获取分类
			this.getSkillCategory()
		},
		mounted() {},

		methods: {
			//获取分类列表
			async getSkillCategory() {
				const res = await getSkillCategory()
				this.navList = res.data
				this.getSkills(res.data[0].id)
			},
			//获取核心技术列表
			async getSkills(categoryId) {
				const res = await getSkills({ categoryId })
				this.list = res.data
				this.$store.commit('coreJiShu/editList', res.data)
			},
			//子传父
			navBtn(id) {
				this.getSkills(id)
			}
		}
	}
</script>
<style lang='scss' scoped>
	.nav_box {
		position: sticky;
		/* bottom: -48%; */
		top: 90px;
	}

	.nav {
		/* position: absolute; */
		/* 	width: 345px; */
		background: #f7b500;
		z-index: 888;
	}
</style>
